import React from 'react'

const FNF = () => {
  return (
    <div>
      <text style={{color:"#FFF"}}>
        FNF for "Family and Friends." <br/> <br/>
      </text>
      <text>
          Added functionalities coming soon..

      </text>



    </div>
  )
}

export default FNF